"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.install = void 0;
require("./progress-bar.scss");
function install() {
    // Prepare the element.
    const el = document.createElement("div");
    el.classList.add("progress-bar");
    // Reference: https://w3c.github.io/aria/#progressbar
    el.setAttribute("aria-role", "progressbar");
    el.style.willChange = "transform";
    el.style.transformStyle = "preserve-3d";
    update(0);
    // Mount the element.
    document.body.appendChild(el);
    // Add event listener.
    document.addEventListener("scroll", () => {
        const scrollTop = document.documentElement.scrollTop;
        const pageHeight = document.documentElement.scrollHeight -
            document.documentElement.clientHeight;
        update(scrollTop / pageHeight);
    });
    function update(value) {
        value = Math.min(1, Math.max(0, value));
        el.style.transform = `translate3d(0px, 0px, 0px) scale3d(${value}, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`;
    }
}
exports.install = install;
